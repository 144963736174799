<template>
  <div class="contact">
    <div class="container bg-light-section p-5" >
      <div class="row" >
          <div class="col-12 col-sm-6" >
            <h3>{{ translate('message.contact.address_header') }}</h3>
            English Mastiff FCI Komorowianka<br />
            05-085 Kampinos
          </div>
          <div class="col-12 col-sm-6" >
            <h3>{{ translate('message.contact.contract_header') }}</h3>
            <a href="mailto:fci.komorowianka@wp.pl" >fci.komorowianka@wp.pl</a><br />
            <a href="tel:+48 664 470 660" >+48 664 470 660</a><br />
            <a href="https://www.facebook.com/English-Mastiff-FCI-Komorowianka-517689788743992/" >
               <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
            </a>
          </div>
        </div>
      </div>
      
      <div class="container bg-light-section p-0" style="height: 600px;" >
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2441.941539158289!2d20.42331725158997!3d52.26260663212825!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4719548f59b162a3%3A0x4df3e81ac70877ea!2sKomor%C3%B3w+69A%2C+05-085+Komor%C3%B3w!5e0!3m2!1spl!2spl!4v1544644493133" width="100%" height="600" frameborder="0" style="border: 0; padding: 0;" allowfullscreen></iframe>
      </div>
  </div>
</template>
