<template>
  <div class="about">
    <div class="container p-5 text-center" style="background-color: #463F3A;">
      <h1 style="color: #F77F00"><b><span v-html="translate('message.puppies.attenction_text')"></span></b></h1>
    </div>

    <div class="container bg-light-section text-center">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3 p-3">
          <vue-plyr :options="options">
            <video
              controls
              crossorigin
              playsinline
              data-poster="poster.jpg"
            >
              <source
                size="720"
                src="@/assets/videos/szczeniaki_film.mp4"
                type="video/mp4"
              />
            </video>
          </vue-plyr>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import AwesomeVideo from "awesome-video-player";
// export default {
//   name: "App",
//   components: {
//     AwesomeVideo
//   },
//   data: () => ({
//     videoOption: {
//       properties: {
//         poster: "@/favicon.ico",
//         src:
//           require('@/assets/videos/szczeniaki_film.mp4'),
//         preload: "auto",

//       },
//       videoStyle: {
//         width: "464px",
//         height: "848px"
//       },
//       controlsConfig: {
//         fullScreenTit:"全屏",
//         EscfullScreenTit:"退出全屏",
//         speedTit:"倍速",
//         volumeTit:"音量",
//         muteTit:"静音",
//         playTit:"播放",
//         pauseTit:"暂停",
//         fullScreen:true,
//         speed:true,
//         listen:true
//       }
//     },
//   }),
//   methods:{
//   }
// };
</script>
