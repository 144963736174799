<template>
  <div class="gallery">
    <div class="container bg-light-section p-5">
      <p class="text-center"><span v-html="translate('message.gallery.content_text')"></span></p>
      <hr />

      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/danny-12-weeks-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/danny-12-weeks-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/Dannyna_wystawie.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/Dannyna_wystawie_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/DSC_0051-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/DSC_0051-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/DSC_0283-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/DSC_0283-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/DSCN6042-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/DSCN6042-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/LittleandLarge-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/LittleandLarge-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/Love-Me-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/Love-Me-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/Mastif_w_biegu.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/Mastif_w_biegu_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/Niktmi_sie_nie_oprze.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/Niktmi_sie_nie_oprze_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/Play-time-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/Play-time-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/przytulasy.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/przytulasy_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/Stadowbiegu.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/Stadowbiegu_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/Tofi_letnia.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/Tofi_letnia_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/tOFI-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/tOFI-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/us2-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/us2-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
        <div class="col-12 col-sm-6 col-md-4 my-2">
          <a
            :href="require('@/assets/images/gallery/us-compressor.jpg')"
            data-lightbox="dogs"
            ><img
              src="@/assets/images/gallery/tumbnails/us-compressor_tn.jpg"
              class="img-thumbnail"
              alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
